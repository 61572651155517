import store from "@/state/store";

export default [
  {
    path: "/",
    name: "PublicSection",
    meta: { authRequired: false},
    component: () => import("./views/public/index"),
  },
  {
    path: "/studio",
    name: "default",
    meta: { authRequired: true},
    component: () => import("./views/instructions/instructions-list"),
  },
  {
    path: "/studio/instructions/list",
    name: "instructions-list",
    meta: { authRequired: true},
    component: () => import("./views/instructions/instructions-list"),
  },
  {
    path: "/studio/instructions/:id/:status",
    name: "instruction-editor",
    meta: { authRequired: true},
    component: () => import("./views/instructions/instruction-editor"),
  },
  {
    path: "/studio/instructions/:documentId",
    name: "instruction-editor-document",
    meta: { authRequired: true},
    component: () => import("./views/instructions/instruction-editor"),
  },
  {
    path: "/studio/instructions/history/:id",
    name: "instruction-history",
    meta: { authRequired: true},
    component: () => import("./views/instructions/instruction-history"),
  },
  {
    path: "/studio/users",
    name: "users-list",
    meta: { authRequired: true},
    component: () => import("./views/users/users-list"),
  },
  {
    path: "/studio/user/profile/:userId",
    name: "user-profile",
    meta: { authRequired: true},
    component: () => import("./views/users/user-profile2"),
  },
  {
    path: "/studio/user/profile-edit/:userId",
    name: "user-profile-edit",
    meta: { authRequired: true},
    component: () => import("./views/users/user-profile-edit"),
  },
  {
    path: "/studio/workorders/list",
    name: "workorders-list",
    meta: { authRequired: true},
    component: () => import("./views/workorders/workorders-list"),
    props: (route) => ({
      assetId: route.query.assetId || undefined,
      status: route.query.status || undefined,
      startDate: route.query.startDate ? new Date(route.query.startDate).getTime() : undefined,
      //endDate: route.query.endDate ? new Date(route.query.endDate).getTime() : undefined,
      result: route.query.result || undefined,
      instructionId: route.query.instructionId || undefined,
      userId: route.query.userId || undefined,
    }),
  },
  {
    path: "/studio/workorder/:workorderId/:userId",
    name: "workorder-view",
    meta: { authRequired: true},
    component: () => import("./views/workorders/workorder"),
  },
  {
    path: "/studio/assets/list",
    name: "assets-list",
    meta: { authRequired: true},
    component: () => import("./views/assets/assets-list"),
  },
  {
    path: "/studio/asset/:assetId",
    name: "asset-view",
    meta: { authRequired: true},
    component: () => import("./views/assets/asset"),
  },
  {
    path: "/studio/models/list/",
    name: "models-list",
    meta: { authRequired: true},
    component: () => import("./views/models/models-list"),
  },
  {
    path: "/studio/models/create/",
    name: "model-create",
    meta: { authRequired: true},
    component: () => import("./views/models/model-create"),
  },
  {
    path: "/studio/model/:id/",
    name: "model-editor",
    meta: { authRequired: true},
    component: () => import("./views/models/model-editor"),
  },
  {
    path: "/studio/model/history/:id/",
    name: "model-history",
    meta: { authRequired: true},
    component: () => import("./views/models/model-history"),
  },
  {
    path: "/studio/model/model-validation/:modelId/:category/:action",
    name: "model-validation",
    meta: { authRequired: true},
    component: () => import("./views/models/model-pictures-validation"),
  },
  {
    path: "/studio/dashboards",
    name: "Dashboards",
    meta: { authRequired: true},
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/studio/procedures/list",
    name: "procedures-list",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedures-list"),
  },
  {
    path: "/studio/procedure/history/:id",
    name: "procedure-history",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedure-history"),
  },
  {
    path: "/studio/procedure/new",
    name: "procedure-editor-new",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedure-editor"),   
  },
  {
    path: "/studio/procedure/:procedureId/:status",
    name: "procedure-editor",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedure-editor"),
    props: (route) => ({    
      procedureId: route.params.procedureId || undefined,
      status: route.params.status || undefined,
      enableJsonEditor: route.query.enableJsonEditor || undefined 
    }),
  },
  {
    path: "/studio/procedure/:documentId",
    name: "procedure-editor-document",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedure-editor"),
    props: (route) => ({    
      documentId: route.params.documentId || undefined,
      enableJsonEditor: route.query.enableJsonEditor || undefined 
    }),
  },
  {
    path: "/studio/procedure_loader/:instructionId",
    name: "procedure-editor-loader",
    meta: { authRequired: true},
    component: () => import("./views/procedures/procedure-editor"),
    props: (route) => ({    
      instructionId: route.params.instructionId || undefined,
      enableJsonEditor: route.query.enableJsonEditor || undefined 
    }),
  },
  {
    path: "/studio/admin",
    name: "Admin",
    meta: { authRequired: true},
    component: () => import("./views/utility/coming-soon"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/studio/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/studio/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {       
          store.dispatch("auth/logOut");  
          const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    }
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  //Redirect any unmatched routes to the 404 page. This may
  //require some server configuration to work in production:
  //https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "404",
  },
];