import firebase from 'firebase/compat/app';
// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";



const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

class FirebaseAuthBackend {
    constructor() {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);

            if (process.env.VUE_APP_AUTH_EMULATOR_HOST !== undefined) {
                firebase.auth().useEmulator(process.env.VUE_APP_AUTH_EMULATOR_HOST);
                firebase.firestore().useEmulator(
                    process.env.VUE_APP_FIRESTORE_EMULATOR_IP,
                    process.env.VUE_APP_FIRESTORE_EMULATOR_PORT);
                firebase.storage().useEmulator(
                    process.env.VUE_APP_FIRESTORE_EMULATOR_IP,
                    process.env.VUE_APP_FIRESTORE_STORAGE_EMULATOR_PORT);
                //firebase.firestore.setLogLevel('debug');                           
            }

            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                    this.getCustomClaims().then((claims) => {
                        if(claims != undefined){
                            user.organization = claims.organizationId
                            sessionStorage.setItem("organizationId", claims.organizationId);     
                            user.role = claims.role
                        }else{
                            console.error("No customer claim for : ", JSON.stringify(user));
                        } 
                    }).catch((error) => {
                        console.error("Error getting user claims:", error);
                    });                    
                } else {
                    sessionStorage.removeItem('authUser');
                }
            });
        }
    }


    /**
     * Registers the user with given details
     */
    registerUser = (username, email, password) => {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            firebase.auth().createUserWithEmailAndPassword(email, password).then((res) => {
                let user = firebase.auth().currentUser.updateProfile({
                    displayName: username
                });
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        console.log("Sign in : " + email)
        return new Promise((resolve, reject) => {
            firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
                var user = firebase.auth().currentUser;
                resolve(user);
            }, (error) => {
                reject(this._handleError(error));
            });
        });
    }

    /**
     * forget Password user with given details
     */
    forgetPassword = (email) => {
        return new Promise((resolve, reject) => {
            firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + "//" + window.location.host + "/login" }).then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise((resolve, reject) => {
            firebase.auth().signOut().then(() => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    }

    setLoggeedInUser = (user) => {
        this.getCustomClaims().then((claims) => {
            user.organization = claims.organizationId
            user.role = claims.role
            sessionStorage.setItem("organizationId", claims.organizationId);     
        }).catch((error) => {
            console.error("Error getting user claims:", error);
        });
        sessionStorage.setItem("authUser", JSON.stringify(user));
    }

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem('authUser'))
            return null;      
        return JSON.parse(sessionStorage.getItem('authUser'));
    }

    /**
     * Returns the user's custom claims
     */
    getCustomClaims = () => {
        console.log("getCustomClaims")
        return new Promise((resolve) => {
            if (!firebase.auth().currentUser) {
                resolve(null);
            } else {
                // Use getIdTokenResult to get the user's claims
                firebase.auth().currentUser.getIdTokenResult()
                    .then((idTokenResult) => {
                        resolve(idTokenResult.claims);
                    })
                    .catch((error) => {
                        console.error("Error getting user claims:", error);
                        resolve(null);
                    });
            }
        });
    }


    /**
     * getFirestore
     */
    getFirestore = () => {
        var db = firebase.firestore();

        return db;
    }



    /**
     * Handle the error
     * @param {*} error 
     */
    _handleError(error) {
        var errorMessage = error.message;
        return errorMessage;
    }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config 
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
}

export { initFirebaseBackend, getFirebaseBackend };